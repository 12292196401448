import React from "react";
// import { FaFacebookF } from "react-icons/fa";
// import { AiFillInstagram, AiFillLinkedin } from "react-icons/ai";
// import { BsYoutube } from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = ({ items }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="bg-lightBlue md:pt-5 pt-2">
      <div className="grid md:grid-cols-3 mt-10 lg:px-32 px-5 pb-5">
        <div className="">
          <Link to="#" smooth onClick={scrollToTop}>
            <img
              className="w-[50%] mx-auto cursor-pointer"
              src="/Assets/logoo.png"
              alt="logo"
            />
          </Link>
          <p className=" my-5 leading-tight mx-auto font-medium text-lg font-gil text-justify">
            We've been dedicated to transforming businesses with our
            cutting-edge ERP solutions. Our commitment to excellence extends to
            every corner of our services. As you embark on this journey of
            growth, know that we are here to support you every step of the way.
            From implementation to ongoing assistance, we're invested in your
            triumph.
          </p>
        </div>
        <div className="md:ml-[40%] md:block hidden">
          <h1 className="text-xl font-medium mt-20 ">Quicklinks</h1>
          {items.map((item) => (
            <Link to={item.link} smooth onClick={scrollToTop}>
              <li className="list-none relative group py-1 cursor-pointer">
                {item.text}
                <span className="absolute bottom-1 left-0 w-0 h-0.5 bg-secondary transition-all group-hover:w-[20%]"></span>
              </li>
            </Link>
          ))}
        </div>
        <div className="md:ml-[25%] md:text-left text-center font-gil">
          <h1 className="md:mt-20 mt-10  font-medium text-xl">Contact Us</h1>

          <p className="leading-loose">
            <a href="tel:+91 9892142731">
              <span>9892142731</span>
            </a>{" "}
            /{" "}
            <a href="tel:+91 8898582056">
              <span>8898582056</span>
            </a>{" "} / {" "}
            <a href="tel:+91 7500445053">
              <span>7500445053</span>
            </a>
          </p>

          <p className="leading-loose">
            Head Office: Shop No. 3, RNA Arcade, Lokhandwala Complex, Andheri
            West, Mumbai 400053
          </p>
        </div>

        <div className="block md:hidden text-center mt-10">
          <h1 className="text-xl font-medium mt-5">Quicklinks</h1>
          {items.map((item) => (
            <Link to={item.link} smooth onClick={scrollToTop}>
              <li className="list-none relative group py-1 cursor-pointer">
                {item.text}
                <span className="absolute bottom-1 left-0 w-0 h-0.5 bg-secondary transition-all group-hover:w-[20%]"></span>
              </li>
            </Link>
          ))}
        </div>
      </div>

      <div className="bg-secondary/[0.70] md:flex justify-around py-1 items-center">
        <div>
          <h1 className="md:py-2 py-5 text-sm font-bold text-center md:text-justify">
            All Right Reserved | TSS Software
          </h1>
        </div>
        {/* <div className="flex justify-center md:py-0 pb-3 items-center">
          <a href="/">
            <FaFacebookF className="w-8 h-8 text-secondary bg-white rounded-full p-1.5 mx-2 hover:bg-secondary hover:text-white border-2 border-white cursor-pointer" />
          </a>

          <a href="/">
            <AiFillInstagram className="w-8 h-8 text-secondary bg-white rounded-full p-1.5 mx-2 hover:bg-secondary hover:text-white border-2 border-white cursor-pointer" />
          </a>

          <a href="/">
            <BsYoutube className="w-8 h-8 text-secondary bg-white rounded-full p-1.5 mx-2 hover:bg-secondary hover:text-white border-2 border-white cursor-pointer" />
          </a>

          <a href="/">
            <AiFillLinkedin className="w-8 h-8 text-secondary bg-white rounded-full p-1.5 mx-2 hover:bg-secondary hover:text-white border-2 border-white cursor-pointer" />
          </a>
        </div> */}
      </div>
    </section>
  );
};

Footer.defaultProps = {
  items: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "About Us",
      link: "/about",
    },
    {
      text: "Products",
      link: "/products",
    },
    {
      text: "Contact Us",
      link: "/contact",
    },
  ],
};

export default Footer;
